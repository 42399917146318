import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

const getHumanReadableLabelFromLayerId = (symbolName: string) => {
  return symbolName
    ?.replaceAll("_", " ")
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export interface PotentialImpactsInteractionProperties
  extends FeatureInteractionProperties {
  burnProbabilities: number[];
  name: string;
  type: string;
}

export type PotentialImpactsInteractionState =
  FeatureInteractionState<PotentialImpactsInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
): PotentialImpactsInteractionProperties | null => {
  if (!isPointFeature(feature)) return null;

  const lngLat = feature.geometry.coordinates as LngLatLike;

  const name = feature.properties?.facname;
  const symbolName = feature.properties?.symbolname;
  const burnProbabilitiesString = feature.properties?.burnProbabilities;

  const burnProbabilities = JSON.parse(burnProbabilitiesString);

  const type = getHumanReadableLabelFromLayerId(symbolName);

  return {
    lngLat,
    name,
    type,
    burnProbabilities,
  };
};
