import { clamp } from "@kablamo/kerosene";
import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";

const COLOR_SCALE = [
  "rgba(0,0,0,0)",
  0.001,
  "#FFEBEC",
  0.1,
  "#FAE5E7",
  0.2,
  "#FFD9DD",
  0.3,
  "#FFBEC6",
  0.4,
  "#FF9EAB",
  0.5,
  "#FF7C94",
  0.6,
  "#FD5E80",
  0.7,
  "#F63B64",
  0.8,
  "#E11B47",
  0.9,
  "#C40B34",
] as const;

const DESATURATED_COLOR_SCALE = [
  "rgba(0,0,0,0)",
  0.001,
  "#cfcfcf",
  0.1,
  "#b1b1b1",
  0.2,
  "#8f8f8f",
  0.3,
  "#868686",
  0.4,
  "#8a8a8a",
  0.5,
  "#747474",
  0.6,
  "#5b5b5b",
  0.7,
  "#878787",
  0.8,
  "#7c7c7c",
  0.9,
  "#5e5e5e",
] as const;

interface EmberDensityLayerProps {
  geojson: FeatureCollection;
  hours: number;
  opacity: number;
  predictionId: string;
}

const EmberDensityLayer = ({
  geojson,
  hours,
  opacity,
  predictionId,
}: EmberDensityLayerProps) => {
  const map = useMapContext();

  const clampedHours = clamp(0, hours, 11);
  const isExpired = clampedHours < hours;
  const colorScale = isExpired ? DESATURATED_COLOR_SCALE : COLOR_SCALE;
  const EMBER_DENSITY_ID = `${predictionId}-ember-density`;

  useEffect(() => {
    map.addSource(EMBER_DENSITY_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });
    map.addLayer(
      {
        id: EMBER_DENSITY_ID,
        type: "fill",
        source: EMBER_DENSITY_ID,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      map.removeLayer(EMBER_DENSITY_ID);
      map.removeSource(EMBER_DENSITY_ID);
    };
  }, [EMBER_DENSITY_ID, map, predictionId]);

  useEffect(() => {
    const source = map.getSource(EMBER_DENSITY_ID);
    if (isGeoJsonSource(source)) {
      source.setData(geojson);
    }
  }, [EMBER_DENSITY_ID, geojson, map, predictionId]);

  useEffect(() => {
    map.setPaintProperty(EMBER_DENSITY_ID, "fill-color", [
      "step",
      ["at", clampedHours, ["get", "emberDensities"]],
      ...colorScale,
    ]);
  }, [predictionId, clampedHours, map, colorScale, EMBER_DENSITY_ID]);

  useEffect(() => {
    map.setPaintProperty(EMBER_DENSITY_ID, "fill-opacity", opacity);
  }, [opacity, map, predictionId, EMBER_DENSITY_ID]);

  return null;
};

export default EmberDensityLayer;
