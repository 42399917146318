import { useCallback, useContext, useMemo } from "react";
import { MapInteractionsContext } from "./MapInteractions";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
  MapInteractionType,
} from "./types";

interface UseScopedFeatureStateParams {
  emptyInteractionState: FeatureInteractionState<FeatureInteractionProperties>;
  layerId: string;
  state: FeatureInteractionState<FeatureInteractionProperties>;
}

const getScopedFeatureState = <P extends FeatureInteractionProperties>({
  emptyInteractionState,
  layerId,
  state,
}: UseScopedFeatureStateParams): FeatureInteractionState<P> => {
  const scopedState = state.layerId === layerId ? state : emptyInteractionState;
  return scopedState as FeatureInteractionState<P>;
};

interface UseLayerInteractionStateParams {
  layerId: string;
  type?: MapInteractionType;
}

type ActivateLayerInteractionStateFn<P extends FeatureInteractionProperties> = (
  properties: P,
) => void;

export interface LayerInteractionState<P extends FeatureInteractionProperties> {
  activateClickState: ActivateLayerInteractionStateFn<P>;
  activateHoverState: ActivateLayerInteractionStateFn<P>;
  clickedState: FeatureInteractionState<P>;
  hoveredState: FeatureInteractionState<P>;
  deactivateHoverState: () => void;
  deactivateClickState: () => void;
}

const useLayerInteractionState = <P extends FeatureInteractionProperties>({
  layerId,
  type = "popup",
}: UseLayerInteractionStateParams): LayerInteractionState<P> => {
  const context = useContext(MapInteractionsContext);

  if (context === undefined) {
    throw new Error(
      "useLayerInteractionState must be used within a MapInteractionsProvider",
    );
  }

  const state =
    type === "popup"
      ? context.popupInteractionState
      : context.persistentInteractionState;

  const {
    activateClickState,
    activateHoverState,
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = state;

  const scopedActivateClickState: ActivateLayerInteractionStateFn<P> =
    useCallback(
      (properties: P) => {
        activateClickState({
          layerId,
          properties,
        });
      },
      [activateClickState, layerId],
    );

  const scopedActivateHoveredState: ActivateLayerInteractionStateFn<P> =
    useCallback(
      (properties: P) => {
        activateHoverState({
          layerId,
          properties,
        });
      },
      [activateHoverState, layerId],
    );

  const emptyInteractionState = useMemo<FeatureInteractionState<P>>(
    () => ({
      id: "",
      isActive: false,
      layerId,
      properties: null,
    }),
    [layerId],
  );

  const scopedClickedState = getScopedFeatureState<P>({
    emptyInteractionState,
    layerId,
    state: clickedState,
  });
  const scopedHoveredState = getScopedFeatureState<P>({
    emptyInteractionState,
    layerId,
    state: hoveredState,
  });

  return {
    activateClickState: scopedActivateClickState,
    activateHoverState: scopedActivateHoveredState,
    deactivateClickState,
    deactivateHoverState,
    clickedState: scopedClickedState,
    hoveredState: scopedHoveredState,
  };
};

export default useLayerInteractionState;
