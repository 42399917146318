import { Close, IconButton, useIsMinWidth } from "@app/design-system";
import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";

const StyledMapPopupView = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

const StyledIconButton = styled(IconButton)`
  margin: 0 0 0 auto;
`;

interface MapPopupViewProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  hasClose?: boolean;
  header?: React.ReactNode;
  onClose: () => void;
}

const MapPopupView = ({
  children,
  "data-testid": dataTestId,
  hasClose = true,
  header,
  onClose,
}: MapPopupViewProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("md");

  const showCloseButton = !!hasClose || !isTabletLandscapeAndAbove;

  return (
    <StyledMapPopupView data-testid={dataTestId}>
      {(header || showCloseButton) && (
        <StyledHeader data-testid={makeTestId(dataTestId, "header")}>
          {header}
          {showCloseButton && (
            <StyledIconButton
              data-testid={makeTestId(dataTestId, "close-button")}
              icon={Close}
              label="Close"
              onClick={onClose}
              size="xs"
              variant="ghost"
            />
          )}
        </StyledHeader>
      )}
      {children}
    </StyledMapPopupView>
  );
};

export default MapPopupView;
